import React, {Component} from 'react';
import SectionsPage from "../../components/SectionsPage";

class Workshops extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }


    render() {
        return (
            <>
                <SectionsPage language={this.props.language} pagetype={'normal'} _id={this.props.cpid} />
            </>
        );
    }
}

export default Workshops;

