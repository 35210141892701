import React, {Component} from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";
// import 'react-lazy-load-image-component/src/effects/blur.css';

class RenderImage extends Component {
    //function renderImage(data,width=550,height=550)
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return <React.Fragment>
            <LazyLoadImage
                className={'img-fluid w-auto h-auto bg-black'}
                alt={this.props.alt}
                height={this.props.height}
                width={this.props.width}
                wrapperClassName={'d-lg-flex w-100 h-auto '}
                src={this.props.image} // use normal <img> attributes as props
                placeholderSrc={this.props.placeholder}
                effect="blur"

            />
        </React.Fragment>
    }
}
export default RenderImage;