import React from 'react';
import {NavLink, useLocation} from "react-router-dom";
import PartnerLogos from "./Image/PartnerLogos";
import { Translation } from 'react-i18next';

const Footer = (props) =>  {
    const { pathname } = useLocation();
    const pathspit = pathname.split("/")
    const isneo=(pathspit[1] !== 'neo' && pathspit[2] !== 'neo'? '' : '/neo')
     return (
        <footer className={'bg-black pt-5 text-white'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-lg-4 pb-5 mb-5'}>
                        <Translation>
                            {
                                (t, { i18n }) => <h3 className={'h6 mb-4 mb-lg-0'}>{t('Organiser')}</h3>
                            }
                        </Translation>
                        <div className={'row'}>
                            <PartnerLogos />
                        </div>
                        <Translation>
                            {
                                (t, { i18n }) => <h3 className={'h6 mt-5 pt-3 mb-4 mb-lg-0'}>{t('TrainingClaim')}</h3>
                            }
                        </Translation>
                        <div className={'row'}>
                            <div className={'col-10 pt-3 pt-lg-4'}>
                                <a href="https://tanzpunkthannover.de/"><img width={'180'} height={'90'} className={'img-fluid'} alt={'Logo Eisfabrik Hannover'}  src={'/static/images/tanzpunkt_hannover.svg'} /></a>
                            </div>
                        </div>
                    </div>
                    {isneo &&
                        <>
                        <div className={'col-lg-8  pb-5'}>
                            <div className={'row'}>
                            <div className={'col-lg-6 mb-4'}>
                                <Translation>
                                    {
                                        (t, { i18n }) => <h3 className={'h6  mb-4'}>{t('Cooperation')}</h3>
                                    }
                                </Translation>
                                <img width={'100'} height={'46'} className={'img-fluid d-block mb-3 '} src={'/static/images/kinderkultur.svg'} alt={'Stadtbezirksrat Südstadt-Bult (Logo)'}/>
                                <img width={'300'} height={'56'} className={'img-fluid d-block mb-3 '} src={'/static/images/stadt_jugendkultur.png'} alt={'Nds. Lottostiftung (Logo)'}/>

                            </div>
                                <div className={'col-lg-6 mb-4'}>
                                    <Translation>
                                        {
                                            (t, { i18n }) => <h3 className={'h6  mb-4'}>{t('Sponsoredby')}</h3>
                                        }
                                    </Translation>
                                    <img width={'250'} height={'75'} className={'img-fluid mb-3 d-block'} src={'/static/images/lhh_250.webp'} alt={'Kulturbüro der Landeshauptstadt Hannover (Logo)'}/>
                                    <img width={'250'} height={'46'} className={'img-fluid d-block mb-3'} src={'/static/images/mwk.svg'} alt={'Nds. Ministerium für Wissenschaft und Kultur (Logo)'}/>

                                </div>
                            </div>
                            </div>
                        </>

                    }

                            {!isneo &&
                                <>
                                <div className={'col-lg-8  pb-5'}>
                                    <div className={'row'}>
                                    <Translation>
                                        {
                                            (t, { i18n }) => <h3 className={'h6  mb-4'}>{t('Sponsoredby')}</h3>
                                        }
                                    </Translation>
                                    <div className={'col-lg-4'}>
                                        <img width={'250'} height={'75'} className={'img-fluid mb-3 d-block'} src={'/static/images/lhh_250.webp'} alt={'Kulturbüro der Landeshauptstadt Hannover (Logo)'}/>
                                        <img width={'250'} height={'46'} className={'img-fluid d-block mb-3'} src={'/static/images/mwk.svg'} alt={'Nds. Ministerium für Wissenschaft und Kultur (Logo)'}/>
                                    </div>
                                    <div className={'col-lg-4'}>
                                        <img width={'250'} height={'46'} className={'img-fluid d-block mb-3 '} src={'/static/images/stadtbezirksrat_suedstadt_bult.png'} alt={'Stadtbezirksrat Südstadt-Bult (Logo)'}/>
{/*
                                        <img width={'240'} height={'46'} className={'img-fluid d-block mb-3 '} src={'/static/images/lottostiftung.svg'} alt={'Nds. Lottostiftung (Logo)'}/>
*/}

                                    </div>
                                    <div className={'col-lg-4'}>
                                        <img width={'200'} height={'46'} className={'img-fluid d-block mb-3 '} src={'/static/images/BKM_2017.svg'} alt={'Nds. Lottostiftung (Logo)'}/>
                                        <img width={'200'} height={'46'} className={'img-fluid d-block mb-3 '} src={'/static/images/NPN_weiss.svg'} alt={'Nds. Lottostiftung (Logo)'}/>

                                    </div>
                                        <div className={'col-lg-12 pt-1'}>
                                            <Translation>
                                                {
                                                    (t, {i18n}) => <p className={'sponsor-info'}>{t('SponsorClaim')}</p>
                                                }
                                            </Translation>
                                        </div>
                                    </div>
                                </div>
                                </>
                            }


                </div>
                <div className={'row pt-3 pb-3'}>
                    <div className={'col-12 '}>
                        <ul className="nav">
                            <li className="nav-item">
                                <NavLink
                                    className={"nav-link ps-0"}
                                    to={(props.language==='de'?'/datenschutz':'/en/datenschutz')}

                                >
                                        <Translation>
                                            {
                                                (t, { i18n }) => <small>{t('privacy')}</small>
                                            }
                                        </Translation>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className={"nav-link ps-0"}
                                    to={(props.language==='de'?'/impressum':'/en/impressum')}
                                >
                                    <Translation>
                                        {
                                            (t, { i18n }) => <small>{t('imprint')}</small>
                                        }
                                    </Translation>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;