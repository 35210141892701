import React, { Component } from 'react';
import Slider from "../components/Slider/Slider";
import {getCollectionUrl} from "../utils";

class MemberSlider extends Component {
    constructor(props){
        super(props);
        console.log(this.props);
        this.state = {
            collection:this.props.collection,
            memberlist: [],
            head:this.props.head,
            subhead:this.props.subhead,
            teaser:this.props.teaser
        }
    }
    getMembers(){
        fetch(getCollectionUrl(this.state.collection), {
            method: 'post',
            mode: 'cors',
            body: JSON.stringify({
                sort: {'_o': 1},
                populate: 1,
            }),
            headers: {
            'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                // console.log(data.entries);
                this.setState({ memberlist: data.entries });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount(){
        this.getMembers();
    }
    renderSlider=function renderSlider(){
        if(this.state.memberlist.length >0)
            return <Slider  memberslist={this.state.memberlist} type={'normal'} dots={false} arrows={true} perView={[1,2,4,6]} spacing={30} />;
        return null;
    }
    render() {
        return (
              <>
                <h2 className={' section-heading'}>{this.state.head}
                    {this.state.subhead!==false &&
                       <React.Fragment><br /><span className={'d-block pt-2 pt-lg-1 fz-40 fw-normal'}>{this.state.subhead}</span></React.Fragment>
                    }
                </h2>
                  {this.state.teaser!==false &&
                      <p className={'lead pt-3 pt-lg-5 pb-5 mb-5'}>{this.state.teaser}</p>
                  }
                  {this.state.subhead === false && this.state.teaser===false &&
                      <p className={'lead mb-5'}/>
                  }
                  {this.renderSlider()}
              </>
        );
    }
}

export default MemberSlider;

