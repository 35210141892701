import React, { Component } from 'react';
import ImageSlider from "../Slider/ImageSlider";

class ImageContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    render() {
        const classes='col-md-6 col-lg-6 col-xl-5 d-flex align-items-start flex-column ps-0 pe-0';
        return (
            <React.Fragment>
                <div className={this.props.alignment==='right' ? ('order-md-last ' + classes):(classes)}>
                    <div className={'d-inline-block d-lg-block col-12 ps-0 pe-0 mt-auto mb-auto'}>
                        <div className="ratio ratio-1x1">
                            {this.props.placeholder ? (
                                <div className=" col-12 ratio ratio-1x1 mt-auto mb-auto">
                                    <span className="d-flex col-12">
                                    </span>
                                </div>
                            ):(
                                <div>
                                    <ImageSlider lazyloading={this.props.lazyloading} arrows={this.props.arrows} dots={true} perView={[1,1,1]} spacing={0} imagelist={this.props.data.imagelist}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )}
}

export default ImageContainer;