import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/scss/main.scss";
import App from './App';
import i18next from "i18next";
import './i18';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));


i18next.init({
    interpolation: {
        escapeValue: false
    },  // React already does escaping
});
const entrypath=window.location.pathname.split('/');
if(entrypath[1]==='en'){
    console.log(i18next.resolvedLanguage);
    localStorage.setItem("language", 'en');
    i18next.changeLanguage('en');

}

const Root = () => <BrowserRouter><App /></BrowserRouter>; // prettier-ignore
root.render(<Root />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
