import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
class PartnerLogos extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {}
    // }
    render() {
        return <React.Fragment>
            <div className={'row'}>
                <div className={'col-8 col-md-4 col-lg-6 pt-lg-3 mt-lg-3'}>
                    <a href="https://eisfabrik.com/"><img  height={200} className={'img-fluid'} alt={'Logo Eisfabrik Hannover'}  src={'/static/images/eisfabrik_2.svg'} /></a>
                </div>
                {/*<div className={'col-4 pt-lg-3 mt-lg-3'}>*/}
                {/*    <a  href="https://commedia-futura.de"><img  height={100} className={'img-fluid'} alt={'Logo Commedia Futura'} src={'/static/images/commedia_futura.svg'} /></a>*/}
                {/*</div>*/}
                {/*<div className={'col-3'}>*/}
                {/*    <NavLink*/}

                {/*        to={'/kunst-in-der-weissen-halle'}*/}
                {/*    >*/}
                {/*        <img width={'90'} height={'90'} className={'img-fluid'} alt={'Schriftzug: Kunst in der Weissen Halle'} src={'/static/images/weisse_halle.svg'} />*/}
                {/*    </NavLink>*/}
                {/*</div>*/}
            </div>
        </React.Fragment>
    }
}
export default PartnerLogos;