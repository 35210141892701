import React, { Component } from 'react';

class ParagraphPlaceholder extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <React.Fragment>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-7"></span>
            </React.Fragment>
        )}
}

export default ParagraphPlaceholder;