import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollTopBehaviour() {
  const { pathname } = useLocation();
 // console.log('ScrollTopBehaviour');
  useEffect(() => {
    // console.log(pathname);
    document.documentElement.scroll({
          top: 0,
          left: 0,
          behavior: 'auto'
        }
    )
  }, [pathname]);

  return null;
}
