export const getImageUrl = path => {
  return (path && `${process.env.REACT_APP_API_URL}/${path.replace(/^\//, '')}`) || null;
};

export const getCollectionUrl = collection => {
  return `${process.env.REACT_APP_API_URL}/api/collections/get/${collection}?token=${process.env.REACT_APP_API_TOKEN}`;
};
export const getSingeleton = singleton => {
  return `${process.env.REACT_APP_API_URL}/api/singletons/get/${singleton}?token=${process.env.REACT_APP_API_TOKEN}`;
}
export const getDatesUrl = getDatesUrl => {
  return `${process.env.REACT_APP_API_URL}/api/public/datesfestivals`;
}
export const getNeoDatesUrl = getNeoDatesUrl => {
  return `${process.env.REACT_APP_API_URL}/api/public/datesfestivals_neo`;
}
export const getDefaultDatesUrl = getDefaultDatesUrl => {
  return `${process.env.REACT_APP_API_URL}/api/public/datesfestivals_default`;
}

export const getImageThumbUrl = image => {
  return `${process.env.REACT_APP_API_URL}/api/cockpit/image/?token=${process.env.REACT_APP_API_TOKEN}`;
};

export const getRegionUrl = region => {
  return `${process.env.REACT_APP_API_URL}/api/regions/data/${region}?token=${process.env.REACT_APP_API_TOKEN}`;
};



