import React, {Component} from 'react';
import SectionsPage from "../../components/SectionsPage";
class Freundeskreis extends Component {

    render() {
        return (
            <>
                <SectionsPage language={this.props.language} pagetype={'sections'} _id={'01dd5e05313039f42d0001a6'} />
            </>
        );
    }
}

export default Freundeskreis;

