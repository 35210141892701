import React, { useState } from 'react';
import {Link,NavLink,Outlet,useLocation } from "react-router-dom";
import {Navbar,Nav,Container,Offcanvas,NavDropdown} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const Mainnavigation = (props) => {
    const [isOpen, setOpen] = useState(false);
    const { pathname } = useLocation();
    const { t } = useTranslation();
    let useNavItems=[];
    const handleTrans = (code) => {
        props.handleSetLanguage(code)
    };
/*
    const [isNeo, setNeo] = useState(false);
*/
    const pathspit = pathname.split("/")
/*    console.log(pathspit);*/
/*    if(pathspit[1]==='neo'||pathspit[2]==='neo'){
        setNeo(true)
    }else{
        setNeo(false)
    }*/
/*
    console.info('isNeo',isNeo)
*/
    const isneo=(pathspit[1] !== 'neo' && pathspit[2] !== 'neo'? '' : '/neo')
    const Navitems=[
        {
            title:'Programm',
            link:'/'
        },
        {
            title:'Workshops',
            link:'/workshops'
        },
        {
            title:'tanzLOKAL',
            link:'/tanzlokal'
        }
    ]
    const NavitemsNeo=[
        {
            title:'Programm',
            link:'/neo'
        },
        {
            title:'Workshops',
            link:'/neo/workshops'
        },
/*        {
            title:'tanzLOKAL',
            link:'/tanzlokal'
        }*/
    ]
    if(isneo){
       useNavItems = NavitemsNeo;
    }else{
        useNavItems =Navitems;
    }
    return (
        <>
            <Navbar
                expanded={isOpen}
                expand={'lg'}
                bg="dark"
                variant="dark"
                fixed={false}
                className={"bg-black pt-lg-3"&&(isneo?' isneo':'')}
            >
                <Container className={'ps-lg-0 pb-2 '}>
                    {(!isneo) &&
                        <>
                        <Link className={"navbar-brand pb-lg-2"} to="/">
                            <img src="/static/images/tanzoffensive_hannover.svg" alt="tanzOFFensive Hannover"
                                 width="190" height="27"/>
                        </Link>
                        <div className={'d-flex justify-content-end'}>
                    {props.language==='de' ?
                        <Link to={'/en'} className="d-inline-flex btn btn-black d-lg-none" onClick={() => handleTrans('en')}><span className={'nav-text'}>EN</span></Link> :
                        <Link to={'/'} className="d-inline-flex btn btn-black d-lg-none" onClick={() => handleTrans('de')}><span className={'nav-text'}>DE</span></Link>
                    }
                        <Navbar.Toggle
                        aria-controls="offcanvasNavbar"
                        aria-expanded={(isOpen ? 'true' : 'false')}
                        onClick={() => setOpen(isOpen ? false : "expanded")}
                        />
                        </div>
                        </>
                    }
                    {(isneo) &&
                        <>
                        <Link className={"navbar-brand pb-lg-2"} to="/neo">
                            <img src="/static/images/NEOTanzoffensive-24-A1_quer.png" alt="[NEO] junge tanzwoche"
                                 width="190" height="27"/>
                        </Link>
                        <div className={'d-flex justify-content-end'}>
                    {props.language==='de' ?
                        <Link to={'/en/neo'} className="d-inline-flex btn btn-black d-lg-none" onClick={() => handleTrans('en')}><span className={'nav-text'}>EN</span></Link> :
                        <Link to={'/neo'} className="d-inline-flex btn btn-black d-lg-none" onClick={() => handleTrans('de')}><span className={'nav-text'}>DE</span></Link>
                    }
                        <Navbar.Toggle
                        aria-controls="offcanvasNavbar"
                        aria-expanded={(isOpen ? 'true' : 'false')}
                        onClick={() => setOpen(isOpen ? false : "expanded")}
                        />
                        </div>
                        </>
                    }


                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        placement="end"
                    >
                        <Offcanvas.Header
                            closeButton
                            className="justify-content-end"
                            onClick={() => setOpen(false)}
                        ></Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className=" ps-0 me-auto mb-2 mb-lg-0">
                                {(!isneo) &&
                                    <NavLink
                                        className={"nav-link main-nav-link has-secondary-font d-lg-none"}
                                        to={(props.language==='de'? '/':'/en')}
                                        onClick={() => setOpen(false)}
                                    >
                                        Home
                                    </NavLink>
                                }
                                {(isneo) &&
                                    <NavLink
                                        className={"nav-link main-nav-link has-secondary-font d-lg-none"}
                                        to={(props.language==='de'? '/neo':'/en/neo')}
                                        onClick={() => setOpen(false)}
                                    >
                                        Home
                                    </NavLink>
                                }
                                {useNavItems?.map((entry, idx) => (
                                    <NavLink
                                        className={"nav-link main-nav-link has-secondary-font"}
                                        to={(props.language==='de'? '':'/en') + entry.link}
                                        onClick={() => setOpen(false)}
                                        key={idx}
                                    >
                                       <span className={'nav-text'}>{t(entry.title)}</span>
                                    </NavLink>
                                ))}
                                {(!isneo) &&
                                    <>
                                    <NavDropdown
                                        title={t('Infos')}
                                        id="navigation-dropdown"
                                        menuVariant="dark"
                                        className={"nav-link main-nav-link has-secondary-font  " + ((pathname === '/festivalinfos' || pathname === '/festivalinfos') ? ' parent active ' : ' parent ')}
                                    >
                                        <NavDropdown.Item onClick={() => setOpen(false)}
                                                          to={(props.language === 'de' ? '/eintrittundkarten' : '/en/eintrittundkarten')}
                                                          as={Link}
                                                          className="nav-link dropdown-item-sub fz-18">{t('Admission')}</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => setOpen(false)}
                                                          to={(props.language === 'de' ? '/kontakt' : '/en/kontakt')}
                                                          as={Link}
                                                          className="nav-link dropdown-item-sub fz-18">{t('Contact')}</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => setOpen(false)}
                                                          to={(props.language === 'de' ? '/anreise' : '/en/anreise')}
                                                          as={Link}
                                                          className="nav-link dropdown-item-sub fz-18">{t('Arrival')}</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavLink
                                    className={"nav-link main-nav-link has-secondary-font"}
                                    to={(props.language==='de'? '/neo':'/en/neo')}
                                    onClick={() => setOpen(false)}
                                    >
                                        <span className={'nav-text'}>[<span className={'neored'}>N<span className={'neopink'}>E</span>O</span>]</span>
                                    </NavLink>
                                    </>
                                }
                                {(isneo) &&
                                    <NavDropdown
                                        title={t('Infos')}
                                        id="navigation-dropdown"
                                        menuVariant="dark"
                                        className={"nav-link main-nav-link has-secondary-font  " + ((pathname === '/festivalinfos' || pathname === '/festivalinfos') ? ' parent active ' : ' parent ')}
                                    >
                                        <NavDropdown.Item onClick={() => setOpen(false)}
                                                          to={(props.language === 'de' ? '/neo/eintrittundkarten' : '/en/neo/eintrittundkarten')}
                                                          as={Link}
                                                          className="nav-link dropdown-item-sub fz-18">{t('Admission')}</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => setOpen(false)}
                                                          to={(props.language === 'de' ? '/neo/kontakt' : '/en/neo/kontakt')}
                                                          as={Link}
                                                          className="nav-link dropdown-item-sub fz-18">{t('Contact')}</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => setOpen(false)}
                                                          to={(props.language === 'de' ? '/neo/anreise' : '/en/neo/anreise')}
                                                          as={Link}
                                                          className="nav-link dropdown-item-sub fz-18">{t('Arrival')}</NavDropdown.Item>
                                    </NavDropdown>
                                }
                            </Nav>
                            {(!isneo) &&
                                <div className="navbar-nav d-none d-lg-flex">
                                    {/*{props.language!=='de' ?*/}
                                    <Link to={'/'}
                                          className={"nav-item nav-link main-nav-link ms-2 btn btn-black" + (props.language === 'de' ? ' active' : '')}
                                          onClick={() => handleTrans('de')}><span
                                        className={'nav-text'}>DE</span></Link>
                                    {/*:*/}
                                    <Link to={'/en'}
                                          className={"nav-item nav-link main-nav-link ms-2 btn btn-black" + (props.language === 'en' ? ' active' : '')}
                                          onClick={() => handleTrans('en')}><span
                                        className={'nav-text'}>EN</span></Link>
                                    {/*}*/}

                                </div>
                            }
                            {(isneo) &&
                                <div className="navbar-nav d-none d-lg-flex">
                                    {/*{props.language!=='de' ?*/}
                                    <Link to={'/neo'}
                                          className={"nav-item nav-link main-nav-link ms-2 btn btn-black" + (props.language === 'de' ? ' active' : '')}
                                          onClick={() => handleTrans('de')}><span
                                        className={'nav-text'}>DE</span></Link>
                                    {/*:*/}
                                    <Link to={'/en/neo'}
                                          className={"nav-item nav-link main-nav-link ms-2 btn btn-black" + (props.language === 'en' ? ' active' : '')}
                                          onClick={() => handleTrans('en')}><span
                                        className={'nav-text'}>EN</span></Link>
                                    {/*}*/}

                                </div>
                            }
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
                </Navbar>
            <Outlet />
        </>
    );
};

export default Mainnavigation;