import React, { useState, useEffect } from 'react';
// import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
// import ImageContainer from "../../components/Image/ImageContainer";
// import TextContainer from "../../components/Text/TextContainer";
// import {LazyLoadImage} from "react-lazy-load-image-component";
import {getCollectionUrl} from "../../utils";
// import WeisseHalleText from "../../components/Text/WeisseHalleText";
import { format } from 'date-fns';
import {de, enGB} from "date-fns/locale";

const WeisseHalle = (props) =>  {
    let params = useParams();
    const _id = params.id;
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(getCollectionUrl('events'), {
                    method: 'post',
                    mode: 'cors',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        filter: {_id:_id},
                        sort: {'startdate': -1},
                        populate: 1, // resolve linked collection items
                    })
                })
                const json = await response.json();
                setData(json.entries[0]);
                setIsLoading(false);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();

    }, [_id]);


    return   <div>
        {(isLoading === true)  &&
            <div className={'container-fluid'}>
                <div className={'container vh-100'}>
                    <div className={'row vh-100'}>
                        <div className=" pe-md-5  col-md-12 d-flex align-items-start flex-column pt-3 pb-5 hyphenation">
                            <h1 className="mt-auto mb-auto ">
                                <span className={'fs-5 lh-1 text-secondary has-primary-font programheading d-inline-block'}><span className="placeholder col-12"></span></span><br />
                                <span className="placeholder col-12"></span>
                                    <React.Fragment>
                                        <span className={'fs-5 text-white has-primary-font  d-block'}><span className="placeholder col-12"></span></span><br />
                                    </React.Fragment>
                            </h1></div>
                    </div>
                </div>
            </div>
        }
        {(isLoading === false && data.image!==null)  &&
            <div className={'container-fluid bgimage position-relative'} style={{backgroundImage: "url(" + process.env.REACT_APP_API_URL + '/api/cockpit/image?token='+ process.env.REACT_APP_API_TOKEN +'&src='+data.image.path+'&m=Thumbnail&h=1080&w=1920&o=true&mime=image/webp' + ")"}}>
                <div className={'container h-100'}>
                <div className={'row h-100'}>
                <div className="d-none d-lg-flex pe-md-5  col-md-12 d-flex align-items-start flex-column pt-3 pb-3 hyphenation">
                    <h1 className="mt-auto mb-auto ">
                        <span className={'fs-5 lh-1 text-secondary has-primary-font programheading d-inline-block'}>{(props.language === "de" ? data.title : data.title_EN)}</span><br />
                        {(props.language === "de" ? data.subtitle : data.subtitle_EN)}
                        {(isLoading === false && data.slogan!==null)  &&
                            <React.Fragment>
                                <span className={'fs-5 text-white has-primary-font  d-block'}>{(props.language === "de" ? data.slogan : data.slogan_EN)}</span><br />
                            </React.Fragment>
                        }
                    </h1></div>
                </div>
                </div>
                {(isLoading === false) &&
                    <>
                        {(typeof data.image.meta !== 'undefined') &&
                            <span className={'img-copyright-right'}>© {data.image.meta.copyright}</span>
                        }
                    </>
                }
            </div>
        }
        {(isLoading === false && data.image!==null)  &&
            <div className={'container d-flex d-lg-none bg-dark'}>
                <div className={'row'}>
                    <div className="pt-5 pe-md-5  col-md-12 d-flex align-items-start flex-column pt-3 pb-3 hyphenation">
                        <h1 className="mt-auto mb-auto ">
                            <span className={'fs-5 lh-1 text-secondary has-primary-font programheading d-inline-block'}>{(props.language === "de" ? data.title : data.title_EN)}</span><br />
                            {(props.language === "de" ? data.subtitle : data.subtitle_EN)}
                            {(isLoading === false && (props.language === "de" ? data.slogan : data.slogan_EN)!==null)  &&
                                <React.Fragment>
                                    <span className={'fs-5 text-white has-primary-font d-block'}>{(props.language === "de" ? data.slogan : data.slogan_EN)}</span><br />
                                </React.Fragment>
                            }
                        </h1></div>
                </div>
            </div>
        }
        <div className={'container-fluid pt-5 bg-dark'}>
            <div className={'container pb-5'}>
        <div className={'row'}>
            <div className={'mb-4 mb-lg-0 col-lg-4'}>
                {(isLoading === false && data.moreDates !== null) &&
                    <React.Fragment>
                        {data.moreDates.map((myDate, index) => {
                            return (
                                <div key={'event-'+index} className={'d-flex justify-content-between w-10 fw-bold fz-24 h2 has-primary-font'}>
                                     <div className="d-flex align-self-start">
                                            {(props.language==='de') ?
                                             <>
                                                 {format(new Date(myDate.value.date+'T00:00:00.000Z'), 'EEEEEE dd.MM.yyyy',{
                                                     locale: de
                                                 })}
                                             </>
                                             :
                                             <>
                                                 {format(new Date(myDate.value.date+'T00:00:00.000Z'), 'EEEEEE dd.MM.yyyy',
                                                     {
                                                         locale: enGB
                                                     })}
                                             </>
                                         }
                                         <span>&nbsp;{myDate.value.time} </span>
                                     </div>
                                    <div className="d-flex align-self-end pe-xl-5 me-xl-5"><a className={'btn btn-sm btn-secondary rounded-pill align-self-end'} href={myDate.value.link}>Tickets</a></div>

                    </div>
                            );
                        })}

                    </React.Fragment>

                }
            </div>
            <div className={'col-lg-8'}>
                <div className={'lead hyphenation'} dangerouslySetInnerHTML={{__html: (props.language === "de" ? data.description : data.description_EN)}}></div>
            </div>
        </div>
        </div>
    </div>
    </div>
};

export default WeisseHalle;

