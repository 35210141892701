import React, { useEffect, useState } from "react";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <div>
          <button className="scroll-top " onClick={scrollToTop}>
            <svg title="Pfeil nach oben" version="1.1" id="scroll-top" x="0px" y="0px"
                 viewBox="0 0 45 45">
              <polyline className="st0" points="37.3,27.3 22.6,12.6 8,27.3 "/>
            </svg>
            {/*<img src="/static/images/chevron-up.svg" alt="arrow-up" />*/}
          </button>
        </div>
      )}
    </>
  );
}
