import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Mainnavigation from "../../components/Mainnavigation";
import Footer from "../../components/Footer";
import {NavLink} from "react-router-dom";
// import "../../components/Slider/Slider";
// import Slider from "../../components/Slider/Slider";

class NotFound extends Component {
    render() {
        return (
            <div className="">
                <Helmet>
                    <title>Eisfabrik Hannover</title>
                </Helmet>
                {/* End Page SEO Content */}
                <div className={'container vh-80'}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h1 className={'pt-4 pt-lg-5 text-uppercase fz-140 pb-0 mb-4'}>404</h1>
                            <p className={'text-uppercase fz-45 has-secondary-font fw-normal'}>Ups, Seite nicht gefunden ;(</p>
                            <p className={'lead mb-5'}>Die Seite, nach der Sie suchten wurde entfernt oder ist vorübergehend nicht verfügbar.</p>
                            <NavLink
                                className={"btn btn-lg btn-dark text-uppercase has-secondary-font fw-bold"}
                                to={'/'}
                            >
                                Zurück zur Startseite
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;

