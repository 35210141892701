import React from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import RenderImage from '../Image/RenderImage'
// import 'lazysizes';
// import 'lazysizes/plugins/attrchange/ls.attrchange';
function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <svg
            onClick={props.onClick}
            className={`arrow ${
                props.left ? "arrow--left" : "arrow--right"
            } ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {props.left && (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            )}
            {!props.left && (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            )}
        </svg>
    )
}

export default function ImageSlider(prop) {
    // console.log(prop);
    const [loaded, setLoaded] = React.useState([])
    const [currentSlide, setCurrentSlide] = React.useState(0)
    // const [opacities, setOpacities] = React.useState([])
    let timeout
    let mouseOver = false
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        loop: true,
        // mode: "free-snap",
        slides: {
            // origin: "center",
            perView: prop.perView[0],
            spacing: prop.spacing
        },
        slideChanged(slider) {
            // const new_opacities = slider.track.details.slides.map((slide) => slide.portion)
            // setOpacities(new_opacities)
            setCurrentSlide(slider.track.details.rel)
        },

        clearNextTimeout() {
        clearTimeout(timeout)
        },
        nextTimeout(slider) {
        clearTimeout(timeout)
        if (mouseOver) return
        timeout = setTimeout(() => {
            slider.next()
        }, 2000)
        },
        created() {
            setLoaded(true)
        },
    })
    React.useEffect(() => {
        const new_loaded = [...loaded]
        new_loaded[currentSlide] = true
        setLoaded(new_loaded)
    }, [currentSlide])

    return (
        <>
            <div className="image-slider navigation-wrapper ms-0 me-0">
                <div ref={sliderRef} className="keen-slider">
                    {prop.imagelist?.map((entry, idx) => (
                        <div
                            key={idx}
                            className={"keen-slider__slide ratio ratio-1x1 number-slide"+idx }
                        >
                                <div><div className={'position-relative'}>
                                    <RenderImage
                                    path={entry.path}
                                    image={ process.env.REACT_APP_API_URL + '/api/cockpit/image?token='+ process.env.REACT_APP_API_TOKEN +'&src='+entry.path+'&m=Thumbnail&h=550&w=550&o=true&mime=image/webp'}
                                    placeholder={entry.styles[3].path}
                                    alt={entry.meta.title}
                                    caption={entry.meta.caption}
                                    lazyloading={prop.lazyloading}
                                    width={550}
                                    height={550}
                                    />
                                    <React.Fragment>
                                        <div className={'img-copyright'}>
                                        {entry.meta.caption!=null &&
                                            <span aria-hidden="true">{entry.meta.caption}<br/></span>
                                        }
                                        {entry.meta.copyright!=null &&
                                            <span aria-hidden="true">Foto: {entry.meta.copyright}</span>
                                        }
                                        </div>
                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {prop.imagelist.length > 1 && prop.arrows && loaded && instanceRef.current && (
                    <>
                        <Arrow
                            left
                            onClick={(e) =>
                                e.stopPropagation() || instanceRef.current?.prev()
                            }
                            disabled={currentSlide === 0}
                        />

                        <Arrow
                            onClick={(e) =>
                                e.stopPropagation() || instanceRef.current?.next()
                            }
                            disabled={
                                currentSlide ===
                                instanceRef.current.track.details.slides.length - 1
                            }
                        />
                    </>
                )}
            </div>
            {prop.imagelist.length > 1 && prop.dots && loaded && instanceRef.current && (
                <div className="dots">
                    {[
                        ...Array(instanceRef.current.track.details.slides.length).keys(),
                    ].map((idx) => {
                        return (
                            <button
                                key={idx}
                                onClick={() => {
                                    instanceRef.current?.moveToIdx(idx)
                                }}
                                className={"dot" + (currentSlide === idx ? " active" : "")}
                            />
                        )
                    })}
                </div>
            )}
        </>
    )
}


