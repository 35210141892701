import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import ImageContainer from "../components/Image/ImageContainer";
import TextContainer from "../components/Text/TextContainer";
import {getCollectionUrl} from "../utils";
import MemberSlider from "../components/MemberSlider";
import ParagraphPlaceholder from "./Placeholder/ParagraphPlaceholder";

class SectionsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true,
            hasSections: false
        }
    }

    getContent() {
        fetch(getCollectionUrl('pages'), {
            mode: 'cors',
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                filter: {_id: this.props._id},
                limit: 1,
                skip: 0,
                populate: 1, // resolve linked collection items
            })
        })
            .then(response => response.json())
            .then(data => {
                var hasSections = false;
                if (data.entries[0].sections == null) {
                    hasSections = false;
                } else {
                    if (data.entries[0].sections.length > 0) {
                        hasSections = true;
                    } else {
                        hasSections = false;
                    }
                }
                this.setState({
                    data: data.entries[0],
                    loading: false,
                    hasSections: hasSections
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.getContent();
    }

    renderSection = function renderSection(data, alignment, placeholder) {
        const classes_1 = 'pt-0 pt-md-5 pb-5   ';
        return <article
            key={data.key}
            data-aos={data.aos}
            data-aos-offset="200"
            className={alignment === 'right' ? ('    ' + classes_1) : (classes_1)}
        >
            <div className={'container'}>
                <div className={'row'}>
                    {data.imagelist &&
                        <React.Fragment>
                            <ImageContainer arrows={true} data={data} alignment={alignment} placeholder={placeholder}/>
                            <TextContainer data={data} alignment={alignment} fullwidth={false}
                                           placeholder={placeholder}/>
                        </React.Fragment>
                    }
                    {data.imagelist === false &&
                        <TextContainer data={data} alignment={alignment} fullwidth={true} placeholder={placeholder}/>
                    }
                </div>
            </div>
        </article>;
    }
    Placeholder = function Placeholder(alignment) {
        return <React.Fragment>
            {this.renderSection({
                imagelist: true
            }, alignment, true)}
        </React.Fragment>
    }

    SectionLayout = function SectionLayout(props) {
        let alignment = '';
        const idx = props.idx;
        let data = {
            key: idx,
            aos: props.section.value.aos,
            image: null,
            imagelist: (props.section.value.image == null || props.section.value.image.length === 0 ? false : props.section.value.image),
            placeholder: (props.section.value.image == null || props.section.value.image.length === 0 ? false : props.section.value.image[0].styles[3].path),
            alt: (props.section.value.image == null || props.section.value.image.length === 0 ? false : props.section.value.image[0].meta.title),
            headline: props.section.value.headline,
            text: props.section.value.text,
            linktext: props.section.value.linktext,
            link: props.section.value.link
        }
        alignment = (idx + 1) % 2 === 0 ? 'left' : 'right';

        return <React.Fragment>
            {this.renderSection(data, alignment, this.props.placeholder)}
        </React.Fragment>
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.state.data[(this.props.language==='de'?'metatitle':'metatitle_EN')]}</title>
                    <meta name="description" content={this.state.data[(this.props.language==='de'?'metadescription':'metadescription_EN')]}/>
                </Helmet>
                {/*<div className={'bg-black'}>*/}
                {/* End Page SEO Content */}
                {/*Section Page*/}
                {this.state.loading && this.props.pagetype !== 'normal' &&
                    <React.Fragment>
                        <div className={'container-fluid  bg-dark '}>
                            <div className="container pt-lg-5  ">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h1 aria-hidden="true"
                                            className="placeholder-glow pt-4 pt-lg-4 fz-80 pb-0 mb-1">
                                            <span className={"placeholder col-7 col-xl-3 bg-white"}></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.Placeholder('right')}
                        {this.Placeholder('left')}
                        {this.Placeholder('right')}
                    </React.Fragment>
                }
                {(this.state.loading === false && this.props.pagetype !== 'normal' && this.props._id!=='01dd5e05313039f42d0001a6') &&
                    <div className={'container-fluid   bg-dark '}>
                        <div className={'container pt-lg-5    '}>
                            <div className={'row'}>
                                <div className={'col-lg-8'}>
                                    {this.props._id==='01dd5e05313039f42d0001a6' ?
                                        <img alt ="tanzLOKAL" src={'/static/images/Tanz_Lokal_white_logo.svg'}  width={'500'}/> :
                                        <h1 className="pt-4 pt-lg-4  fz-80 pb-0 mb-1">
                                            {this.state.data[(this.props.language==='de'?'title':'title_EN')]}
                                        </h1>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(this.state.loading === false && this.props.pagetype !== 'normal' && this.props._id==='01dd5e05313039f42d0001a6') &&
                    <div>
                    <div className="container-fluid bgimage position-relative" style={{backgroundImage: "url(" + process.env.REACT_APP_API_URL + '/api/cockpit/image?token='+ process.env.REACT_APP_API_TOKEN +'&src=/storage/uploads/2023/03/07/schwarzer_saal_v2_uid_6406f27527132.jpg&m=Thumbnail&h=1080&w=1920&o=true&mime=image/webp' + ")"}}>
                        <div className="container h-100">
                            <div className="row h-100">
                                <div
                                    className="d-none d-lg-flex pe-md-5  col-md-12 d-flex align-items-start flex-column pt-3 pb-3 hyphenation">
                                    <h1 className="mt-auto mb-auto ">
                                        <img alt ="tanzLOKAL" src={'/static/images/Tanz_Lokal_white_logo.svg'}  width={'500'}/>
                                    </h1></div>
                            </div>
                        </div>
                        <span className="img-copyright-right">© Archiv Eisfabrik </span>
                       </div>
                    </div>
                }
                {this.state.data.sections?.map((section, idx) => (
                    <React.Fragment key={idx}>
                        {this.SectionLayout({
                            idx: idx,
                            section: section
                        })}
                    </React.Fragment>
                ))}
                {/*Normal Page*/}
                {this.state.loading && (this.props.pagetype === 'normal') &&
                    <React.Fragment>
                        <div className={'container-fluid  bg-dark '}>
                            <div className="container pt-lg-5    ">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 aria-hidden="true"
                                            className="placeholder-glow pt-4 pt-lg-4 fz-80 pb-0 mb-1">
                                            <span className="placeholder col-7 col-xl-3"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'container pt-5 pb-5 htmltext lead hyphenation'}>
                            <p aria-hidden="true" className={'placeholder-glow '}>
                                <ParagraphPlaceholder/>
                                <ParagraphPlaceholder/>
                                <ParagraphPlaceholder/>
                                <ParagraphPlaceholder/>
                            </p>
                        </div>
                    </React.Fragment>
                }
                {(this.state.loading === false && this.props.pagetype === 'normal' && this.state.hasSections === false) &&
                    <div className={'container-fluid bg-dark'}>
                        <div className={'container pt-lg-5  '}>
                            <div className={'row'}>
                                <div className={'col-lg-12'}>
                                    <h1 className="pt-4 pt-lg-4  fz-80 pb-0 mb-1">
                                        {this.state.data[(this.props.language==='de'?'title':'title_EN')]}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/*</div>*/}
                <div className={'container-fluid bg-dark'}>
                <div className={'container pt-5 pb-5 htmltext lead hyphenation'}>
                <div className={'row'}>
                    <div className={'col-lg-12'} dangerouslySetInnerHTML={{__html: this.state.data[(this.props.language==='de'?'html':'html_EN')]}} />
                </div>
                </div>
                </div>
            </>
        );
    }
}

export default SectionsPage;

