import React, {Component} from 'react';

import SectionsPage from "../../components/SectionsPage";

class Anreise extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    /*'4c63f52c35336444d90000d3'*/

    render() {
        console.log(this.props.cpid)
        return (
            <>
                <SectionsPage language={this.props.language} pagetype={'normal'} _id={this.props.cpid} />
            </>
        );
    }
}

export default Anreise;

