import React, {useEffect,useState} from "react";
import {
    Routes,
    Route
} from "react-router-dom";
import {Helmet} from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Mainnavigation from "./components/Mainnavigation";
import Footer from "./components/Footer";
import Home from "./views/home/HomeDefault";
import HomeNeo from "./views/home/HomeNeo";
import Freundeskreis from "./views/innerpages/Freundeskreis";
import WeisseHalleItem from "./views/innerpages/WeisseHalle";
import Kontakt from "./views/innerpages/Kontakt";
import Anreise from "./views/innerpages/Anreise";
import Workshops from "./views/innerpages/Workshops";
import Eintritt from "./views/innerpages/Eintritt";
import Impressum from "./views/innerpages/Impressum";
import Datenschutz from "./views/innerpages/Datenschutz";
import NotFound from "./views/innerpages/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import ScrollTopBehaviour from "./components/ScrollTopBehaviour";
import { useTranslation } from 'react-i18next';

const App = () => {
    const {i18n} = useTranslation();
    let languageStoredInLocalStorage = localStorage.getItem("language");
    let [language, setLanguage] = useState(
        languageStoredInLocalStorage ? languageStoredInLocalStorage : "de"
    );
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>tanzOFFensive Hannover</title>
                <meta name="description" content="Festival der freien Tanzszene in Hannover."/>
                <meta
                    name="keywords"
                    content="Hannover, Südstadt, Eisfabrik, Veranstaltungszentrum, Veranstaltungen, Künstler, Kunst, Theater, Tanz"
                />
            </Helmet>
            <Mainnavigation
                language={language}
                handleSetLanguage={language => {
                    setLanguage(language);
                    storeLanguageInLocalStorage(language);
                    i18n.changeLanguage(language)
                }}
            />
            <main>
                <ScrollTopBehaviour/>
                <Routes>

                    <Route path="/"  element={<Home language={language}/>} animate={true}/>
                    <Route path="/events/:id"  element={<WeisseHalleItem language={language}/>} animate={true}/>
                    <Route path="/workshops" element={<Workshops language={language} cpid={'4d214da2386262832f0002e5'}/>} animate={true}/>
                    <Route path="/tanzlokal" element={<Freundeskreis language={language}/>} animate={true}/>

                    <Route path="/eintrittundkarten" element={<Eintritt language={language} cpid={'55ebc8c7386132867f000281'}/>} animate={true}/>
                    <Route path="/kontakt" element={<Kontakt language={language} cpid={'f87b7be2343635517000014e'}/>} animate={true}/>
                    <Route path="/festivalinfos" element={<Kontakt language={language} cpid={'f87b7be2343635517000014e'}/>} animate={true}/>
                    <Route path="/anreise" element={<Anreise language={language} cpid={'4c63f52c35336444d90000d3'}/>} animate={true}/>

                    <Route path="/impressum" element={<Impressum language={language}/>} animate={true}/>
                    <Route path="/datenschutz" element={<Datenschutz language={language}/>} animate={true}/>

                    <Route path="/en"  element={<Home language={language}/>} animate={true}/>
                    <Route path="/en/events/:id"  element={<WeisseHalleItem language={language} />} animate={true}/>
                    <Route path="/en/workshops" element={<Workshops language={language} cpid={'4d214da2386262832f0002e5'}/>} animate={true}/>
                    <Route path="/en/tanzlokal"  element={<Freundeskreis language={language}/>} animate={true}/>

                    <Route path="/en/eintrittundkarten" element={<Eintritt language={language} cpid={'55ebc8c7386132867f000281'}/>} animate={true}/>
                    <Route path="/en/kontakt" element={<Kontakt language={language}  cpid={'f87b7be2343635517000014e'} />} animate={true}/>
                    <Route path="/en/festivalinfos" element={<Kontakt language={language} cpid={'f87b7be2343635517000014e'}/>} animate={true}/>
                    <Route path="/en/anreise" element={<Anreise language={language} cpid={'4c63f52c35336444d90000d3'}/>} animate={true}/>

                    <Route path="/en/impressum" element={<Impressum language={language}/>} animate={true}/>
                    <Route path="/en/datenschutz" element={<Datenschutz language={language}/>} animate={true}/>

                    {/*NEO*/}
                    <Route path="/neo"  element={<HomeNeo language={language}/>} animate={true}/>
                    <Route path="/neo/events/:id"  element={<WeisseHalleItem language={language}/>} animate={true}/>
                    <Route path="/neo/workshops" element={<Workshops language={language} cpid={'83e567d732643793320003e7'}/>} animate={true}/>

                    <Route path="/neo/eintrittundkarten" element={<Eintritt language={language} cpid={'83b711f5613734a6a700030a'}/>} animate={true}/>
                    <Route path="/neo/kontakt" element={<Kontakt language={language} cpid={'83a8d0503531626bdd00032c'}/>} animate={true}/>
                    <Route path="/neo/festivalinfos" element={<Kontakt language={language} cpid={'83a8d0503531626bdd00032c'}/>} animate={true}/>
                    <Route path="/neo/anreise" element={<Anreise language={language} cpid={'827726c9343539f7760002ff'}/>} animate={true}/>

                    <Route path="/neo/impressum" element={<Impressum language={language}/>} animate={true}/>
                    <Route path="/neo/datenschutz" element={<Datenschutz language={language}/>} animate={true}/>

                    <Route path="/en/neo/"  element={<HomeNeo language={language}/>} animate={true}/>
                    <Route path="/en/neo/events/:id"  element={<WeisseHalleItem language={language} />} animate={true}/>
                    <Route path="/en/neo/workshops" element={<Workshops language={language} cpid={'83e567d732643793320003e7'}/>} animate={true}/>

                    <Route path="/en/neo/eintrittundkarten" element={<Eintritt language={language} cpid={'83b711f5613734a6a700030a'}/>} animate={true}/>
                    <Route path="/en/neo/kontakt" element={<Kontakt language={language} cpid={'83a8d0503531626bdd00032c'}/>} animate={true}/>
                    <Route path="/en/neo/festivalinfos" element={<Kontakt language={language} cpid={'83a8d0503531626bdd00032c'}/>} animate={true}/>
                    <Route path="/en/neo/anreise" element={<Anreise language={language} cpid={'827726c9343539f7760002ff'}/>} animate={true}/>

                    <Route path="/en/neo/impressum" element={<Impressum language={language}/>} animate={true}/>
                    <Route path="/en/neo/datenschutz" element={<Datenschutz language={language}/>} animate={true}/>

                    <Route
                        path="*"
                        element={<NotFound  language={language}/>}
                    />
                </Routes>
            </main>
            <Footer language={language} />
            <ScrollToTop/>
        </>
    );
}
function storeLanguageInLocalStorage(language) {
    localStorage.setItem("language", language);
}
export default App;

