import React, { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-overlays/Modal';
import styled from 'styled-components'
function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <svg
            onClick={props.onClick}
            className={`arrow ${
                props.left ? "arrow--left" : "arrow--right"
            } ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {props.left && (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            )}
            {!props.left && (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            )}
        </svg>
    )
}
const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;
const RandomlyPositionedModal = styled(Modal)`
  position: fixed;
  width: auto;
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
  overflow-y:auto;
`;
function renderImage(entry){
    if(entry.image!==null){
        if(entry.image.styles[0].path!==''){
            return <LazyLoadImage
                className={'img-fluid rounded-circle'}
                alt={entry.title}
                height={140}
                width={140}
                src={process.env.REACT_APP_API_URL +  entry.image.styles[0].path} // use normal <img> attributes as props
            />
        }else{
            return null;
        }
    }else{
        return null;
    }
}

function Setcard(props) {
    const [show, setShow] = useState(false);
    const entry=props.person;
    const renderBackdrop = (props) => <Backdrop {...props} />;
    return (
        <div className="modal-example">
            <div className={'text-center'}>
                <div  onClick={() => setShow(true)}>
                {renderImage(entry)}
                </div>
                <div className={'mh-72'}>
                    <p onClick={() => setShow(true)} >
                        <span className={'btn btn-link text-dark text-decoration-none mt-3 mb-1  has-secondary-font fz-20 lh-1 fw-bold'}>{entry.title}</span><br/>
                        <span className={'d-block small text-muted'}>{entry.subtitle}</span>
                        </p>
                </div>
            </div>
            <RandomlyPositionedModal
                show={show}
                onHide={() => setShow(false)}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
                className={'info-modal'}
            >
                <div>
                    <div className={'container-fluid'}>
                        <div className={'row'}>
                            <div className={'col-12 text-end'}>
                                <button
                                    type="button"
                                    className="btn btn-lg btn-dark mb-4"
                                    onClick={() => setShow(false)}
                                >
                                    <img src={'/static/images/close_white_24dp.svg'}/>
                                    <span className={'d-none'}>Schließen</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'col-12 col-lg-12'}>
                                <h1 id="modal-label" className=" fz-140 pb-0 mb-0">{entry.title}</h1>
                                <small className={'d-block fz-40 fw-normal has-secondary-font text-uppercase'}>{entry.subtitle}</small>
                            </div>
                            <div className={'d-none col-12 d-lg-block col-lg-4 col-xl-2 pt-5 text-center'}>
                                <div className={'mt-auto mb-auto'}>
                                {renderImage(entry)}
                                </div>
                            </div>
                            <div className={'col-12 col-lg-8 col-xl-8 pt-5'}>
                                <p>{entry.description}</p>
                                {renderLink(entry)}
                                <p className={'mt-3 mb-0'}>
                                {entry.email &&
                                    <a className={'text-dark'} href={'mailto:'+entry.email}>{entry.email}</a>
                                }
                                {(entry.email && entry.phone) && <span> | </span>}
                                {entry.phone &&
                                   <span>{entry.phone}</span>
                                }
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </RandomlyPositionedModal>
        </div>
    )
}
function renderLink(entry){
    if(entry.link!==null){
        return <a className={'btn btn-lg-lg btn-dark has-secondary-font fw-bold fz-20 text-uppercase'} href={entry.link} title={entry.link}>{entry.link.replace(/((http||https):\/\/)/gm,'')}</a>;
    }else{
        return null;
    }
}
export default function Slider(prop) {
    const [loaded, setLoaded] = React.useState([])
    const [currentSlide, setCurrentSlide] = React.useState(0)
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        loop: true,
        // mode: "free-snap",
        slides: {
            // origin: "center",
            perView: prop.perView[0],
            spacing: prop.spacing
        },
        breakpoints: {
            "(min-width: 576px)": {
                //sm
                slides: { perView: prop.perView[1], spacing: prop.spacing },
            },
            "(min-width: 992px": {
                //lg
                slides: { perView: prop.perView[2], spacing: prop.spacing },
            },
            "(min-width: 1200px)": {
                //xl
                slides: { perView: prop.perView[3], spacing: prop.spacing },
            },
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
    })
    React.useEffect(() => {
        const new_loaded = [...loaded]
        new_loaded[currentSlide] = true
        setLoaded(new_loaded)
    }, [currentSlide])

    return (
        <>
            <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider">
                    {prop.memberslist?.map((entry, idx) => (
                        <div
                            key={idx}
                            className={"keen-slider__slide number-slide"+idx}
                        >
                            <Setcard person={entry}/>
                        </div>
                    ))}
                </div>
                {prop.arrows && loaded && instanceRef.current && (
                    <>
                        <Arrow
                            left
                            onClick={(e) =>
                                e.stopPropagation() || instanceRef.current?.prev()
                            }
                            disabled={currentSlide === 0}
                        />

                        <Arrow
                            onClick={(e) =>
                                e.stopPropagation() || instanceRef.current?.next()
                            }
                            disabled={
                                currentSlide ===
                                instanceRef.current.track.details.slides.length - 1
                            }
                        />
                    </>
                )}
            </div>
            {prop.dots && loaded && instanceRef.current && (
                <div className="dots">
                    {[
                        ...Array(instanceRef.current.track.details.slides.length).keys(),
                    ].map((idx) => {
                        return (
                            <button
                                key={idx}
                                onClick={() => {
                                    instanceRef.current?.moveToIdx(idx)
                                }}
                                className={"dot" + (currentSlide === idx ? " active" : "")}
                            />
                        )
                    })}
                </div>
            )}
        </>
    )
}


